<template>
  <header />
  <nav class="wow animated slideInUp">
    <el-carousel ref="carouselRef" :interval="6000" type="card" class="carousel" height="620px" @change="change">
      <el-carousel-item v-for="(item,index) in carousel" :key="item.key" class="carousel-item" :class="[{'left-one':index<carouselIndex,'left-two':carouselIndex===index,'left-three':index-carouselIndex===1,'left-four':index-carouselIndex===2}]">
        <img :src="item.img" class="img">
      </el-carousel-item>
    </el-carousel>
    <div class="shadow" />
  </nav>
  <section class="xiao-fei wow animated slideInUp">
    <p v-html="`掌握消费主动权<br/>靠近和行动才是实现理想生活的驱动力`" />
  </section>
  <l-bar class="l-bar wow animated slideInUp" />
  <l-business class="wow animated slideInUp" />
  <l-business-number class="l-business-number wow animated slideInUp" />
  <section class="button wow animated slideInUp">
    <div class="user blue" @click="jump('/user')">
      <img src="./images/icon_004.png">
      <span>我是用户</span>
    </div>
    <div class="business green" @click="jump('/business')">
      <img src="./images/icon_005.png">
      <span>我是商户</span>
    </div>
  </section>
  <section class="ling-chuang wow animated slideInUp">
    <div class="ling-chuang-title">领创在中国</div>
    <img src="./images/img_lingchuang.png" class="ling-chuang-img">
    <div class="ling-chuang-content">
      <div>跨国品牌实力雄厚</div>
      <div>国际金融机构认可</div>
      <div>前沿科技服务消费</div>
      <div>致力赋能品牌商户</div>
    </div>
    <div class="ling-chuang-data" v-html="`领创集团是AI技术驱动的科技集团，总部位于新加坡，集团致力于以技术赋能为核心，打造一个服务于消费者、企业和商户的市场生态系统。<br/>基于中国庞大的消费市场，领创集团寻找全球结构性投资和发展机会，在北京、重庆、上海、深圳设立四大中心，统筹商业产品研发、企业大数据建设、<br/>数字金融防控等综合业务的发展。<br/>其中立享花是领创集团旗下的新兴场景消费科技品牌，通过科技创新为消费者提供本地化服务，引领时尚消费。目前已在中国受到众多品牌商和消费者的<br/>青睐。`" />
    <a class="liao-jie" @click="jumpOutside">了解更多 ></a>
  </section>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import LBar from './component/l-bar'
import LBusiness from './component/l-business.vue'
import lBusinessNumber from './component/l-business-number.vue'
import { useRouter } from 'vue-router'
import { WOW } from 'wowjs'
import { nextTick, onMounted } from 'vue'
const carousel = [
  { img: require('./images/img_chaowanjia.png'), title: 'red', key: 0 },
  { img: require('./images/img_guihuajia.png'), title: 'orange', key: 1 },
  { img: require('./images/img_zumengjia.png'), title: 'yellow', key: 2 },
  { img: require('./images/img_lexiangjia.png'), title: 'green', key: 3 },
  { img: require('./images/img_chaowanjia.png'), title: 'blue', key: 4 },
  { img: require('./images/img_guihuajia.png'), title: 'pink', key: 5 }
]// 轮播图
const router = useRouter()
const jump = (url) => {
  router.push(url)
}
const carouselIndex = ref(0)
const carouselRef = ref(null)
const change = (index) => {
  console.log(index)
  if (index === 0) {
    carouselRef.value.setActiveItem(3)
  } else if (index === 4) {
    carouselRef.value.setActiveItem(1)
  } else {
    carouselIndex.value = index
  }
}// 走马灯触发
const jumpOutside = () => {
  window.open('https://www.advancegroup.com.cn/', '_blank')
}
onMounted(() => {
  nextTick(() => {
    carouselRef.value.setActiveItem(1)
    carouselIndex.value = 1
  })
  new WOW().init()
})
</script>
<style lang='less'>
.el-carousel__indicators--outside{
  position: absolute;
  bottom: 50px;
}
.el-carousel__arrow--left,.el-carousel__arrow--right{
  background: grey !important;
}
.el-carousel__indicators--horizontal{
  left:45% !important;
}
</style>
<style lang='less' scoped>
header{
  width:100%;
  height:700px;
  background:url('./images/bg_01.png') no-repeat;
  background-size:100%;
  margin-top:-90px;
  // margin-bottom:-190px;
}
.left-one{
  transform:translateX(-100px) scale(0.83) !important;
}
.left-two{
  margin-left:-50px;
}
.left-three{
  transform:translateX(250px) scale(0.83) !important;
}
.left-four{
  transform:translateX(300px) scale(0.83) !important;
}
.l-bar{
  margin-top:150px;
}
.carousel{
  width:1400px;
  margin:100px auto 0;
  .carousel-item{
    width:1200px;
  }
}
.is-active{
  transform: translateX(130px) scale(1) !important;
}
.img{
  width:100%;
  height:620px;
}
.shadow{
  margin:-10px auto 0;
  width:1100px;
  height:100px;
  background: linear-gradient(
            grey 0%,
            white 100%
        );
  opacity: 0.2;
  filter: blur(10px);
}
.xiao-fei{
  margin:40px auto 0;
  width:100%;
  height:300px;
  background:url('./images/bg_a.png') no-repeat;
  background-size:100% 100%;
  font-size: 40px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #191919;
  line-height: 55px;
  display:flex;
  align-items: center;
  justify-content:center;
  text-align:center;
  >p{
    margin:10px 0;
  }
}
.l-business-number{
  margin-top:118px;
}
.button{
  width:590px;
  display:flex;
  justify-content:space-between;
  margin:70px auto 0;
  .blue{
    background: #4481FD;
    opacity:1;
  }
  .blue:hover{
    opacity:0.8;
  }
  .green{
    background: #34CC98;
    opacity:1;
  }
  .green:hover{
    opacity:0.8;
  }
  .user,.business{
    width: 260px;
    height: 80px;
    border-radius: 10px;
    font-size: 30px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
    display:flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    >img{
      width:32px;
      height:32px;
    }
    >span{
      padding-left:15px;
    }
  }
}
.ling-chuang{
  margin:206px auto 0;
  .ling-chuang-title{
    text-align:center;
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #191919;
  }
  .ling-chuang-img{
    display: block;
    width:1200px;
    height: 490px;
    margin:50px auto 0;
  }
  .ling-chuang-content{
    width: 415px;
    margin:69px auto 0;
    font-size: 24px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #191919;
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
    >div{
      width: 200px;
      line-height:40px;
    }
  }
  .ling-chuang-data{
    width: 1250px;
    margin:48px auto 0;
    font-size: 18px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #666666;
    line-height:30px;
  }
  .liao-jie{
    display: block;
    width:1200px;
    margin:23px auto 147px;
    font-size: 16px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #4481FD;
    text-align: right;
    cursor: pointer;
  }
}
</style>
